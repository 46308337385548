<template>
  <div>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal($event)"
      size="lg"
      class="pa-3"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />
      <CRow>
        <CCol sm="12">
          <CInput
            :label="$t('label.name')"
            :placeholder="$t('label.cargoGroupName')"
            :horizontal="{label:'col-sm-4', input:'col-sm-5'}"
            addLabelClasses="required text-right"
            class="ml-1 w-100 float-left"
            v-uppercase
            v-model.trim="$v.GpoCargoName.$model"
            :invalid-feedback="errorMessage($v.GpoCargoName)"
            :is-valid="hasError($v.GpoCargoName)"
          />
        </CCol>
        <CCol sm="12" v-if="isEdit">
          <CSelect
              :label="$t('label.status')"
              :horizontal="{label:'col-sm-4', input:'col-sm-5'}"
              :options="statusOptions"
              add-label-classes="text-right"
              :value.sync="Status"
              class="float-right ml-1 w-100"
              :is-valid="statusSelectColor"
          />
        </CCol>
      </CRow>

      <!-- #### Table ####   -->
    <CRow class="m-2">
       <CCol sm="12">
        <dataTableExtended
           class="align-center-row-datatable"
           hover
           sorter
           small
           column-filter
           :table-filter="tableText.tableFilterText"
           :items-per-page-select="tableText.itemsPerPageText"
          :items="computedUnitMeasureList"
           :fields="fields"
           :noItemsView="tableText.noItemsViewText"
           :items-per-page="5"
           pagination
          :loading="loadingTable"
        >
        <template #loading>
          <loading/>
        </template>
        <template #options="{ item }">
          <td class="text-center">
            <CInputCheckbox
              :checked="item.initialActive"
              @update:checked="updatedJsonCreated($event, item)"
              class="checkbook-filter"
              custom
            />
          </td>
        </template>
        </dataTableExtended>
          </CCol>
      </CRow>
      <p v-if="invalidUnits" class="mt-2 text-danger required">
        {{$t('label.must')+' '+$t('label.toSelect')+' '+$t('label.atLeast')}} 1 {{$t('label.measurementUnit')}}
      </p>
      <template #footer>
        <CButton v-if="!isEdit"
                outline 
                color="add" 
                @click.stop="submit"
              
          >
          <CIcon name="checkAlt" v-if="!isSubmit"/>
          <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          &nbsp;{{$t('button.accept')}}
        </CButton>
        <CButton v-else
                outline 
                color="add" 
                @click.stop="statusConfirmation( GroupCargoSelect.FgActGpoCargo, Status, submit, GpoCargoName);"
               
          >
          <CIcon name="checkAlt" v-if="!isSubmit"/>
          <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          &nbsp;{{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="closeModal(false)">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import { FormGpoCarga } from '@/_validations/grupocarga/grupocargavalidations';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { DateFormater } from '@/_helpers/funciones';

//### Valores de la data inicial ###
function data() {
  return {
    showModal: false,
    GpoCargoName: '',
    loadingOverlay: false,
    unitMeasureItems: [],
    loadingTable: false,
    TpUnitMeasureJson: [],
    invalidUnits: false,
    Status: 0,
    GroupCargoSelect: {},
  };
}

function fields(){
  return [
      { label: '#', key: 'Nro', sorter: false, filter: false },
      {
        label: this.$t('label.measurementUnits'),
        key: 'TpUnitMeasureName',
        _classes: 'text-uppercase',
      },
      { key: 'options', label: this.$t('label.applies'), sorter: false, filter: false },
      {
        label: this.$t('label.measurementUnitList'),
        key: 'UnitMeasureDetail',
        _classes: 'text-uppercase',
      },
    ];
}

//### Function para cerrar el modal ###
function closeModal(event) {
  this.showModal = false;
  this.$emit('updated-modal', event);
}

//### Function para iniciar la data de la tabla ###
async function mountedTpUnitMeasure() {
  this.loadingTable = true;
  await this.$http
    .get('UnitMeasureDetail-List?Filter=ACTIVO')
    .then((response) => {
      this.unitMeasureItems = response.data.data;
      if (response.data.data.length > 0 && !this.isEdit) {
        const tpUnitTemporal = {
          TpUnitMeasureId: response.data.data[0].TpUnitMeasureId,
        };
        this.TpUnitMeasureJson.push(tpUnitTemporal);
      }
      if (response.data.data.length > 0 && this.isEdit) {
        if(this.GpoCargaSelected && this.GpoCargaSelected.TpUnitMeasureJson !== null) {
            let temporalUnitMeasureItems = [];
            response.data.data.map((item) => {
                let temporalTpUnitMeasureobject = this.GpoCargaSelected.TpUnitMeasureJson.filter(element => 
                    element.TpUnitMeasureId === item.TpUnitMeasureId
                );
                if(temporalTpUnitMeasureobject.length > 0 && temporalTpUnitMeasureobject[0].Status === 'ACTIVO'){
                    const temporalItem = {
                        ...item,
                        initialActive: true,
                    }
                    const tpUnitTemporal = {
                        TpUnitMeasureId: item.TpUnitMeasureId,
                    };
                        this.TpUnitMeasureJson.push(tpUnitTemporal);
                        temporalUnitMeasureItems.push(temporalItem);
                }else{
                    const temporalItem = {
                        ...item,
                        initialActive: false,
                    }
                    temporalUnitMeasureItems.push(temporalItem);
                }
            })
            this.unitMeasureItems = temporalUnitMeasureItems;
        }else{
            this.unitMeasureItems = response.data.data
        }
      }
    })
    .finally(() => {
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//### Function para iniciar la data de la tabla ###
function computedUnitMeasureList() {
  if (this.unitMeasureItems.length > 0 && !this.isEdit) {
    return this.unitMeasureItems.map((item) => {
      let index = this.TpUnitMeasureJson.findIndex((type) => type.TpUnitMeasureId == item.TpUnitMeasureId);
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        initialActive: index != -1 ? true : false,
      };
    });
  }if(this.unitMeasureItems.length > 0 && this.isEdit) {
        return this.unitMeasureItems.map((item, index) => {
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      };
    });
  }
}

// ### Actualiza los datos de los TpUnitMeasureJson ###
function updatedJsonCreated(event, item) {
  const tpUnitTemporal = {
    TpUnitMeasureId: item.TpUnitMeasureId,
  };
  if (event === true) {
    let i = this.TpUnitMeasureJson.findIndex((type) => type.TpUnitMeasureId == item.TpUnitMeasureId);
    if (i == -1) {
      this.TpUnitMeasureJson.push(tpUnitTemporal);
    }
  } else {
    let i = this.TpUnitMeasureJson.findIndex((type) => type.TpUnitMeasureId == item.TpUnitMeasureId);
    if (i != -1) {
      this.TpUnitMeasureJson.splice(i, 1);
    }
  }
}

//funcion para guardar o modificar
async function submit() {
  try {
    this.loadingOverlay = true;
    this.$v.$touch();

    if(this.TpUnitMeasureJson.length === 0){
        this.loadingOverlay = false;
        this.invalidUnits = true;
      throw this.$t('label.errorsPleaseCheck');
    }

     if (this.$v.$pending || this.$v.$error)  {
                throw this.$t('label.errorsPleaseCheck');
             } 

    const GpoCargoJson = this.isEdit?Object.assign({}, {
        GpoCargoId: this.GpoCargaSelected.GpoCargoId,
        GpoCargoName: this.GpoCargoName,
        Status: this.Status,
        TpUnitMeasureJson: this.TpUnitMeasureJson,
    }):{
        GpoCargoName: this.GpoCargoName,
        TpUnitMeasureJson: this.TpUnitMeasureJson
    };
    let metodo = this.isEdit? 'PUT':'POST';
    let ruta = this.isEdit? 'GpoCargo-update':'GpoCargo-insert';

    await this.$http.ejecutar(metodo, ruta, GpoCargoJson, {root: 'GpoCargoJson'} ).then((response) => {
        if(response.status === (200 || 201)){
            const messageSuccess = response.data.data[0].Response
            this.$emit('refresh-data-table');
            this.loadingOverlay = false;
            this.invalidUnits = false;
            this.closeModal(false);
            this.notifySuccess({ text: messageSuccess });
        }
    }).catch((e) => {
        this.loadingOverlay = false;
        this.invalidUnits = false;
        this.notifyError({ text: e });
    })
  } catch (e) {
    this.loadingOverlay = false;
    this.notifyError({ text: e });
  }
}


function title(){
  if(this.isEdit)
    return this.$t('label.edit')+' '+this.$t('label.cargoGroup')+': '+this.GpoCargoName;
  else 
    return this.$t('label.nuevo')+' '+this.$t('label.cargoGroup');
}

function setGpoCarga(val){
  this.GroupCargoSelect = Object.assign({}, val);
  this.Status = val.FgActGpoCargo?1:0;
  this.GpoCargoName = val.GpoCargoName;
  this.unitMeasureItems = [];
  this.TpUnitMeasureJson = [];
}

function statusSelectColor() {
  return (this.Status === 1)?true:false;
}

export default {
  name: 'modalGpoCarga',
  props: { 
    modal: null, 
    isEdit: Boolean, 
    GpoCargaSelected: Object 
  },
  data,
  validations(){ return FormGpoCarga() },
  components: {},
  mixins: [
    GeneralMixin,
    ModalMixin,
  ],
  directives: UpperCase,
  methods: {
    closeModal,
    mountedTpUnitMeasure,
    updatedJsonCreated,
    submit,
    setGpoCarga,
  },
  computed: {
    //statusSelectColor,
    computedUnitMeasureList,
    fields,
    title,
  },
  watch: {
    modal: function(val) {
      this.showModal = val;
      if (val === true) {
        this.mountedTpUnitMeasure();
      }
    },
    GpoCargaSelected: function(val){
        this.setGpoCarga(val);
    },
    isEdit: function(val){
      if(val)
        this.$v.$touch();
      else
        this.$v.$reset();
    }
  },
  
};
</script>
<style lang="scss">
</style>
